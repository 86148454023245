import { configs } from "./interceptors"





let URLData = "https://api.wanjiayizhan.com" 
// let URLData = "http://192.168.1.183:8110" 
// let URLData = "https://2580ez8812.zicp.fun" 
// let URLData = "/api" 


const url = () => {
  return  URLData 
}

//get请求
export const get = (urls, params) => {
  return {
    method: "get",
    url: url() + urls,
    params
  }
}

//post请求
export const post = (urls, data) => {
  // if(urls=="/checkout/addfreelicense"){
  //   urls="s"+urls
  // }
  return {
    method: "post",
    url: url() + urls,
    data,
    // headers: {
    //   // "Content-Type": "multipart/form-data"
    //   // 'Content-Type':'application/json'
    //   // 'Content-Type':'application/x-www-form-urlencoded',
     
    // }

  }
}

//下载
export const down = (urls, params) => {
  return {
    method: "get",
    url: url() + urls,
    params,
    responseType: "blob"
  }
}

//虚贝get请求获取物品信息
export const xb_get = (urls, params) => {
  return {
    method: "get",
    url:   urls,
    params
  }
}
//虚贝支付 订单接口
export const xb_order = (urls, data) => {
  return {
    method: "post",
    url: "https://gg-api.wanjiayizhan.com/api/order/v1"+  urls,
    data
  }
}


export const api = (method, urls, params) => {
  switch (method) {
    case "get":
      return configs(get(urls, params))
    case "post":
      return configs(post(urls, params))
    case "down":
      return configs(down(urls, params))
    case "xb_get":
      return configs(xb_get(urls, params))
    case "xb_order":
      return configs(xb_order(urls, params))
    default:
      break
  }
}
