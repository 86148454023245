<template>
  <div>
    <transition name="fade">
      <div class="login-box" v-show="$store.state.isloginshow">
        <div class="left-box"></div>
        <div class="right-box">
          <div class="close_btn" @click="closedFn"></div>
          <div class="title">登录/注册</div>
          <div class="phone_box">
            <div class="logo_pic"></div>
            <el-input
              class="input_box"
              maxLength="11"
              v-model="ruleForm.phone"
              placeholder="请输入手机号"
              oninput="if(isNaN(value)) { value = null }"
            ></el-input>
          </div>
          <div class="code_box">
            <div class="logo_pic"></div>
            <el-input
              class="input_box"
              maxLength="6"
              v-model="ruleForm.code"
              placeholder="验证码"
              oninput="if(isNaN(value)) { value = null }"
            ></el-input>
            <span @click="sendCode" class="send_code">{{ codename }}</span>
          </div>
          <div class="error_box">
            <span class="error_code">{{ error_code }}</span>
          </div>
          <div class="login_btn" @click="login">
            <span class="login_name">登录</span>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { getPhoneCode, login, getUserInfobyTK } from '@/service/api'
import '@/views/steam/TJCaptcha.js'

export default {
  data () {
    return {
      ruleForm: {
        phone: '',
        code: ''
      },
      send_code_flag: false,
      codename: '发送验证码',
      error_code: ' ',
      //定时器相关
      times: 0,
      timer: null
    }
  },
  methods: {
    //跳转
    jump_url () {
      if (this.$store.state.current_nav_index == 1) {
        this.$router.push('/')
        if (this.$store.state.global_tk != '') {
          this.$store.state.global_tk_url =
            '?token=' + this.$store.state.global_tk
        }
        this.$store.state.global_url =
          'https://gg-web.wanjiayizhan.com/Store' +
          this.$store.state.global_tk_url
      }
      if (this.$store.state.current_nav_index == 2) {
        this.$router.push('/')
        if (this.$store.state.global_tk != '') {
          this.$store.state.global_tk_url =
            '?token=' + this.$store.state.global_tk
        }
        this.$store.state.global_url =
          'https://gg-web.wanjiayizhan.com/Wjyz' +
          this.$store.state.global_tk_url
      }
      if (this.$store.state.current_nav_index == 3) {
        this.$router.push('/')
        if (this.$store.state.global_tk != '') {
          this.$store.state.global_tk_url =
            '&token=' + this.$store.state.global_tk
        }
        this.$store.state.global_url =
          'https://gg-web.wanjiayizhan.com/AllGame?from=wjyz&index=4' +
          this.$store.state.global_tk_url
      }
      if (this.$store.state.current_nav_index == 4) {
        this.$router.push('/download')
      }
    },
    //登录
    async login () {
      if (this.ruleForm.phone != '' && this.ruleForm.code != '') {
        let cn = 1545
        if (this.$store.state.channel_code != '') {
          let d_index = this.$store.state.downfile_channel_list.findIndex(
            item => item.code == this.$store.state.channel_code
          )
          if (d_index != -1) {
            cn = this.$store.state.downfile_channel_list[d_index].channelNumber
          }
        }
        this.error_code = ''
        const data = await login({
          phone: this.ruleForm.phone,
          code: this.ruleForm.code,
          channelNumber: cn, //主站
          // channelNumber: 129,//百度推广
          // channelNumber: 130,//360推广
          type: 0
        })
        //console.log(data)
        //console.log(data.code)
        //console.log(data.data.token)

        if (data.code === 200) {
          let global_tk_temp = data.data.token
          if (global_tk_temp.substring(0, 3) == 'gw:') {
            global_tk_temp = global_tk_temp.slice(3)
          }
          this.$store.state.global_tk = global_tk_temp
          //console.log(this.$store.state.global_tk)
          this.$store.state.isloginshow = false
          this.ruleForm.code = ''
          this.jump_url()
          let tt_token = data.data.token
          if (tt_token.substring(0, 3) != 'gw:') {
            tt_token = 'gw:' + tt_token
          }
          localStorage.setItem('token', tt_token)
          this.getinfo()
          this.$toast({
            message: '登录成功!',
            position: 'top'
          })
        } else {
          this.error_code = '验证码错误！'
        }
      } else {
        this.error_code = '*手机号或验证码不能为空'
      }
    },
    async getinfo () {
      const data = await getUserInfobyTK({})
      if (data.code === 200) {
        this.$store.state.phone = data.data.phone
        this.$store.state.login_title = this.$store.state.phone + '【退出】'
      }
    },
    //关闭窗口
    closedFn () {
      this.$store.state.isloginshow = false
      this.ruleForm.code = ''
    },


    //
     sendCode () {
      try {
        var captcha = new TencentCaptcha(
          '195704878',
         async res => {
            console.log(res)
            if (res.ret === 0) {
              const { ticket, randstr } = res
              await this.sendcodeFn(ticket, randstr)
            }
            else{
               console.log("验证失败")
            }
          },
          {
            userLanguage: 'zh-cn',
            showFn: ret => {
              const {
                duration, // 验证码渲染完成的耗时(ms)
                sid // 链路sid
              } = ret
            }
          }
        )
        // 调用方法，显示验证码
        captcha.show()
      } catch (error) {
        console.log(error)

      }
    },
    async sendcodeFn (ticket, randstr) {
      if (!this.send_code_flag) {
        if (this.ruleForm.phone != '') {
          const reg = /^1[3|4|5|7|8|9][0-9]\d{8}$/
          if (!reg.test(this.ruleForm.phone)) {
            this.error_code = '*请输入正确的手机号'
          } else {
            const data = await getPhoneCode({
              phone: this.ruleForm.phone,
              ticket:ticket,
              randstr:randstr
            })
            this.send_code_flag = true
            this.error_code = ''
            this.times = 60
            this.codename = '已发送(' + this.times + ')'
            this.timer = setInterval(() => {
              if (this.times != 0) {
                this.times = this.times - 1
                this.codename = '已发送(' + this.times + ')'
              } else {
                this.clearInterval()
              }
            }, 1000)
            //console.log(data)
            if (data.code === 200) {
            }
          }
        } else {
          this.error_code = '*手机号不能为空'
        }
      }
    },
    clearInterval () {
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
        this.codename = '发送验证码'
        this.send_code_flag = false
      }
    }
  },
  mounted () {}
}
</script>

<style scoped lang="less">
.login-box {
  width: 625px;
  height: 319px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: #252525;
  border-radius: 5px;
  position: fixed;
  display: flex;
  .left-box {
    width: 384px;
    height: 100%;
    background: url(~@/assets/images/new/login/leftbox.png);
  }
  .right-box {
    width: 241px;
    height: 100%;
    .close_btn {
      width: 12px;
      height: 12px;
      margin-left: 218px;
      margin-top: 11px;
      background: url(~@/assets/images/new/login/close_btn.png);
      cursor: pointer;
    }
    .close_btn:hover {
      background: url(~@/assets/images/new/login/close_btn.png);
      background-position: -12px 0px;
    }
    .title {
      color: #5dfe97;
      font-size: 20px;
      font-weight: bold;
      margin-left: 10px;
      margin-top: 21px;
      width: 100px;
      height: 40px;
    }
    .phone_box {
      width: 206px;
      height: 40px;
      margin-left: 14px;
      margin-top: 27px;
      border: 1px solid #7e7e7e;
      border-radius: 5px;
      display: flex;
      .logo_pic {
        width: 24px;
        height: 24px;
        margin-left: 6px;
        margin-top: 8px;
        background: url(~@/assets/images/new/login/phone.png);
      }
      .input_box {
        margin-left: 10px;
        margin-top: 5px;
      }
    }
    .phone_box:hover {
      border: 1px solid #5dfe97;
    }
    .code_box {
      width: 206px;
      height: 40px;
      margin-left: 14px;
      margin-top: 17px;
      border: 1px solid #7e7e7e;
      border-radius: 5px;
      display: flex;
      .logo_pic {
        width: 24px;
        height: 24px;
        margin-left: 6px;
        margin-top: 8px;
        background: url(~@/assets/images/new/login/code.png);
      }
      .input_box {
        margin-left: 10px;
        margin-top: 5px;
        width: 70px;
      }
      .send_code {
        font-size: 14px;
        color: #5dfe97;
        cursor: pointer;
        height: 20px;
        margin-top: 10px;
        margin-left: 20px;
      }
      .send_code:hover {
        color: #9dffc1;
      }
    }
    .code_box:hover {
      border: 1px solid #5dfe97;
    }
    .error_box {
      display: flex;
      .error_code {
        font-size: 12px;
        color: #ff5c5c;
        margin-left: 20px;
      }
    }
    .login_btn {
      width: 206px;
      height: 40px;
      margin-left: 14px;
      margin-top: 17px;
      border-radius: 5px;
      background: url(~@/assets/images/new/login/login_btn.png);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      .login_name {
        font-size: 14px;
        color: #1c1c1c;
      }
    }
    .login_btn:hover {
      background-position: -206px 0px;
    }
  }
  /deep/.el-input__inner {
    height: 28px;
    width: 120px;
    background: #232323;
    border: none;
    font-size: 16px;
    // font-family: PingFangSC-Regular, PingFang SC;
    color: #d4d4d4;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.8s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
