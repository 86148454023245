import { api } from "./index"
import Vue from "vue"

let $api = {}

/* import {} from "@/service/api"; */

/* 业务逻辑 *
/* -------------------登录------------------- */

//测试测试
export const getQrCode = params => {
  return api("get", "/user-center/user/wxlogin/getQrCode", params)
}
export const WXlogin = params => {
  return api("get", "/user-center/user/wxlogin/check/login", params)
}

//获取登录验证码
export const getPhoneCode = params => {
  return api("post", "/user-center/user/userinfo/newjyz/sms", params)
 
}

//登录
export const login = params => {
  return api("post", "/user-center/user/userinfo/newjyz/login", params)
}
//根据token获取用户信息
export const getUserInfobyTK = params => {
  return api("post", "/user-center/user/userinfo/newjyz/info", params)
}
//下载打点
export const addDown = params => {
  return api("post", "/open-platform/pro/addDown", params)
}
//虚贝接口
//获取商品价格
export const xb_getProInfo = params => {
  return api("xb_get", "https://api.steamboxs.com/api/getProductDetail", params)
}
//获取会员价格
export const xb_getHYInfo = params => {
  return api("xb_get", "https://gg-api.wanjiayizhan.com/api/order/v1/product", params)
}
//创建订单
export const xb_create_order = params => {
  return api("xb_order", "/create_order", params)
}
//查询订单支付状态
export const xb_order_status = params => {
  return api("xb_order", "/order_status", params)
}


//挂载原型
export const apiTest = () => { }
Vue.prototype.$api = $api
